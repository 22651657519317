






























































































































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';

import {
  Inventory,
  inventoryType,
  inventoryActionValidation
} from '../models/inventory.model';
import DriverInputWrapper from '@/shared/components/form/DriverInputWrapper.vue';
import { states } from '@/shared/models/geo.model';
import { WorkOrderService } from '@/shared/services/wo/wo-import.service';
import {
  DATE_API_FORMAT,
  DATE_API_FORMAT_WITHOUT_TIME,
  DATE_TIME_FORMAT
} from '@/utils/date.util';
import moment from 'moment-timezone';
import { YmsService } from '@/shared/services/yms/yms.service';
import Loading from '@/shared/components/Loading.vue';
import { ToastHelper } from '@/utils/toast.util';
import { DivisionModule, YmsModule } from '@/store/index';
import { YmsType } from '../models/yms.model';

Component.registerHooks(['validations']);

@Component({
  components: {
    Fragment,
    DriverInputWrapper,
    Loading
  }
})
export default class InventoryAction extends Vue {
  divOptions = [];
  typeOptions = inventoryType;
  state = states;

  containerType = null;
  containerSize = null;
  chassisPool = null;
  shippingLine = null;
  selectValues = null;

  inventoryInst = new Inventory();
  form: any = this.inventoryInst;
  loading = false;

  creationDay = moment().format(DATE_API_FORMAT_WITHOUT_TIME);
  creationTime = moment().format('HHmm');

  constructor() {
    super();
  }

  get fieldAvalability() {
    return YmsModule.ymsType == YmsType.INVENTORY;
  }

  get inventoryId() {
    return YmsModule.inventoryId;
  }

  get isCorrectDay() {
    //ONLY If it's an add modal then we need to check
    if (this.inventoryId) {
      return true;
    }

    const day = moment(this.form.date, DATE_API_FORMAT).format(
      DATE_API_FORMAT_WITHOUT_TIME
    );

    return this.creationDay <= day;
  }

  get isCorrectTime() {
    //ONLY If it's an add modal then we need to check
    if (this.inventoryId) {
      return true;
    }

    return (
      this.creationTime <= moment(this.form.time, 'HH:mm:ss').format('HHmm')
    );
  }

  created() {
    this.getSelectValues();
    this.form.date = moment().format(DATE_API_FORMAT);
    this.form.time = moment().format(DATE_TIME_FORMAT);
    this.form.division = this.divOptions[0].value;
  }

  mounted() {
    if (this.inventoryId) {
      this.getInventoryData();
    }

    this.divOptions = DivisionModule.divisionsList.map(i => ({
      ...{
        value: i.abbr,
        text: i.name
      }
    }));
  }

  validations() {
    return {
      form: inventoryActionValidation
    };
  }

  validateState(name) {
    const { $dirty, $error } = this.$v.form[name];
    return $dirty ? !$error : null;
  }

  close() {
    this.$bvModal.hide('inventory-action-modal');
  }

  isFormInValid() {
    this.$v.form.$touch();
    return this.$v.form.$anyError;
  }

  getSplitDayAndTime() {
    const day = moment(this.form.date, 'DATE_API_FORMAT').format(
      DATE_API_FORMAT_WITHOUT_TIME
    );
    const time = moment(this.form.time, 'HH:mm').format('HHmmss');

    return `${day}${time}`;
  }

  test() {
    const day = moment(this.form.date, 'DATE_API_FORMAT').format(
      DATE_API_FORMAT_WITHOUT_TIME
    );

    const time = moment(this.form.time, 'HH:mm:ss').format('HHmmss');

    console.log(time, this.creationTime);
  }

  async getInventoryData() {
    this.loading = true;
    try {
      const invetoryResponse = await YmsService.getInventory(this.inventoryId);
      this.form = { ...invetoryResponse.data };

      this.form.time = moment(this.form.date, DATE_API_FORMAT).format(
        DATE_TIME_FORMAT
      );
      this.loading = false;
    } catch (err) {
      this.loading = false;
      ToastHelper.show(
        `${this.$route.params.type}/${this.$route.name.replace(
          `${this.$route.params.type}-`,
          ''
        )}`,
        err.message,
        5000,
        'danger'
      );
    }
  }

  async save() {
    if (this.isFormInValid()) {
      return;
    }

    if (!this.isCorrectTime) {
      return;
    }

    this.loading = true;
    this.form.date = this.getSplitDayAndTime();
    try {
      if (!this.inventoryId) {
        await YmsService.addInventory(this.form);
      } else {
        if (this.form.id) {
          await YmsService.updateInventory(this.form);
        } else {
          throw Error('Incorrect id');
        }
      }

      this.loading = false;
      YmsModule.search({ type: YmsType[YmsModule.ymsType] });
      this.$bvModal.hide('inventory-action-modal');
      ToastHelper.show(
        `${YmsType[YmsModule.ymsType]}`,
        'Successfully saved',
        3000,
        'success'
      );
    } catch (err) {
      this.loading = false;
      ToastHelper.show(
        `${this.$route.params.type}/${this.$route.name.replace(
          `${this.$route.params.type}-`,
          ''
        )}`,
        err.message,
        5000,
        'danger'
      );
    }
  }

  async getSelectValues() {
    const { containerType, containerSize, chassisPool, shippingLine } =
      await WorkOrderService.getSelectValues();
    this.containerType = containerType;
    this.containerSize = containerSize;
    this.chassisPool = chassisPool;
    this.shippingLine = shippingLine;
  }
}
